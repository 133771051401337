<template>
	<div class="container-fluid">
		<div class="row">
			<div class="col-lg-12">
				<div class="d-flex flex-wrap flex-wrap align-items-center justify-content-between mb-4">
                    <div>
                        <h4 class="mb-3">{{title}}</h4>
                        <p class="mb-0">{{desc1}}<br> {{desc2}}<br>{{desc3}}</p>
                        <!-- <p v-b-modal.modal-3 variant="link" class="mb-0"><u>판매량 모니터링 상품 추가 방법</u></p>
                        <div v-if="showspinner"><img style="max-width:10%;" :src="require('../../../assets/images/small/loading.gif')">{{loadingtext}}</div>
                            <b-modal id="modal-3" size="xl" title="쿠팡 모니터링 상품 추가 방법" ok-only>
                                <p><a href="https://chrome.google.com/webstore/detail/couplus/bogokmehpkdjafnlhjhinfgmeljdgmgh?hl=ko&authuser=0" target="_blank"><u>구글웹스토어</u></a>에 접속해서 "COUPLUS"를 다운받은 후 쿠플러스에 로그인한 다음 <b style="color:red"><u>"쿠팡 상품페이지"에서</u></b> "링크수집(판매량)" 버튼을 클릭해주세요.<br> 한번 추가된 상품은 매일 1회 자동으로 수집됩니다.</p>
                                <img  style="max-width:100%;" :src="require('../../../assets/images/small/모니터링추가.png')">
                            </b-modal> -->
					</div>
					<!-- <b-button variant="primary pl-5 pr-5" class="mt-2 mr-1" @click="collecttr">{{linktext}}</b-button> -->
                </div>
			</div>

			<div class="col-lg-12" v-if="showlist">
				<b-button variant="outline-primary" class="mb-1 mr-2" @click="showts">
				트렌드세터
				</b-button>
				<b-button variant="outline-secondary" class="mb-1 mr-2" @click="showtf">
				트렌드팔로워
				</b-button>
			</div>

			<!-- <div class="col-lg-6 mt-3" v-if="showlist">
				<b-button variant="outline-success pl-5 pr-5" class="mt-2 mr-1 mb-2" v-b-toggle.my-collapse>판매량 보정</b-button>
				<b-collapse id="my-collapse">
					<b-card title="">
						보정치 적용 시 마이너스 판매량을 0으로 바꾸어 줍니다. (마이너스 임계치가 -2일경우 -2이하의 판매량은 모두 0으로 적용)
						<br>
						<br>
						[마이너스 임계치]
						<div class="form-group d-inline-block d-flex justify-content-between">
							<input type="number" class="form-control" placeholder="마이너스 임계치" v-model="minuslimit">

						</div>
						<div class="custom-control custom-switch custom-control-inline">
								<input type="checkbox" class="custom-control-input" id="customSwitch2" v-model="applyminuslimit" @change="applycorrect">
								<label class="custom-control-label" for="customSwitch2" v-b-tooltip.bottom title="전체 페이지에서 검색하며, 여러개가 검색될 경우 페이지랭킹이 여러게로 나타납니다.">보정치 적용</label>
							</div>
					</b-card>
				</b-collapse>
			</div> -->

			<div class="col-lg-12 mt-3" v-if="showlist" style="font-size:80%">
				<div class="table-responsive rounded mb-3">
                    <CDataTable :headers="headers" :items="saleslists" v-model="tableSelectedItem" :show-select="true" itemKey="_id">
                        <template v-slot:로켓뱃지   ="{item}">
                            <div style="width:100px;overflow:hidden;text-overflow: ellipsis; padding: 15px; text-align: center">
                                <div>{{ item.type == 'trendSetter' ? 'Trend Setter' : 'Trend Follower' }}</div>
                                <span v-if="!item.로켓뱃지">없음</span><img v-if="item.로켓뱃지" :src="item.로켓뱃지" alt="image" style="max-width:100%">
                            </div>
                        </template>
                        <template v-slot:판매상품명="{item}">
                            <div style="min-width:250px;overflow:hidden;text-overflow: ellipsis; padding: 10px">
                                <div class="d-flex align-items-center">
                                    <img :src="item.썸네일" class="img-fluid rounded avatar-100 mr-3" alt="image" @click="openWebsite(item.판매링크)">
                                    <div>
                                        {{item.판매상품명}}
                                        <p class="mb-0" style="color: #676E8A"><small>ProductId: {{item.productId}}</small></p>
                                    </div>
                                </div>
                            </div>
                        </template>
                        <template v-slot:구매가="{item}">
							<td style="min-width:250px;overflow:hidden;text-overflow:ellipsis;">
								<div class="d-flex justify-content-between">
									<div class="d-flex justify-content-center">
										<img :src="item.상품매칭[item.currentindex].pic_url" class="img-fluid rounded avatar-100" alt="image" @click="linktopurchase(item.상품매칭[item.currentindex])">
										<div class="d-flex flex-column">
											<b-badge variant="white" v-b-modal="modalIDca(index)"><b-icon icon="search" scale="2" variant="dark"></b-icon></b-badge>
											<b-badge variant="white" @click="previmg(item,true)"><b-icon icon="arrow-left-square" scale="2" variant="dark"></b-icon></b-badge>
											<b-badge variant="white" @click="nextimg(item,true)"><b-icon icon="arrow-right-square" scale="2" variant="dark"></b-icon></b-badge>
										</div>
									</div>
									<div class="d-flex flex-column mt-1 w-100">
										<div class="d-flex justify-content-between align-items-center w-100 text-center">
											CNY <input type="number" class="form-control ml-1 text-center" placeholder="구매가(CNY)" v-model="item.구매가" @change="changeCost(item)">
										</div>
										<div class="d-flex justify-content-between align-items-center w-100 text-center">
											&nbsp;&nbsp;&nbsp;&nbsp;x&nbsp;&nbsp;&nbsp;&nbsp; <input type="number" class="form-control ml-1 text-center" placeholder="매입가율" v-model="item.매입가율" @change="changeCost(item)">
										</div>
									</div>
								</div>

								<b-modal :id="'modalca'+index" size="sm" scrollable title="상품매칭" ok-title="적용" cancel-title="취소" class="detail-modal" style="font-size:80%" @ok="applyCurrentObj(item)">
									<div class="d-flex justify-content-center h-100 w-100">
										<div class="h-100 w-50">
											<b-img class="mh-100 mw-100" rounded :src="item.썸네일" alt="Responsive image"></b-img>
										</div>
										<div class="h-100 w-50 d-flex justify-content-center align-items-center">
											<button type="button" class="btn btn-outline-dark mr-2" @click="previmg(item,false)"><b>&lt;</b></button>
											<div id="carouselExampleControls" class="h-100 carousel slide mr-2" data-bs-ride="carousel">
												<div class="h-100 carousel-inner">
													<div class="carousel-item active h-100">
														<img :src="item.상품매칭[item.currentindex].pic_url" class="d-block h-100" alt="...">
													</div>
												</div>
											</div>
											<button type="button" class="btn btn-outline-dark" @click="nextimg(item,false)">&gt;</button>
										</div>
									</div>
								</b-modal>
							</td>
                            <!-- <div class="d-flex flex-column mt-1 w-100">
                                <div class="d-flex justify-content-between align-items-center w-100 text-center">
                                    CNY <input type="number" class="form-control ml-1 text-center" placeholder="구매가(CNY)" v-model="item.구매가" @change="changeCost(item)">
                                </div>
                                <div class="d-flex justify-content-between align-items-center w-100 text-center">
                                    &nbsp;&nbsp;&nbsp;&nbsp;x&nbsp;&nbsp;&nbsp;&nbsp; <input type="number" class="form-control ml-1 text-center" placeholder="매입가율" v-model="item.매입가율" @change="changeCost(item)">
                                </div>
                            </div> -->
                        </template>
                        <template v-slot:월수익="{item}">
                            {{ item.월수익.toLocaleString() }}
                        </template>
                        <template v-slot:마진="{item}">
                            <td style="overflow:hidden;text-overflow: ellipsis;" v-if="item.마진">{{item.마진.toLocaleString()}}<br>({{item.마진율.toFixed(1)}}%)<br>({{item.ROI ? item.ROI.toFixed(1) : ''}}%)</td>
                            <td v-else>0</td>
                        </template>
                        <template v-slot:판매가="{item}">
                            <input type="number" class="form-control w-100 text-center" placeholder="판매가" v-model="item.판매가" @change="changeCost(item)">
                        </template>
                        <template v-slot:수수료="{item}">
                            {{item.수수료}}
                            <div v-b-modal="'commision' + item._id">
                                <b-badge variant="white"><b-icon icon="calculator" scale="2" variant="dark"></b-icon></b-badge>
                            </div>

                            <b-modal :id="'commision' + item._id" size="sm" title="수수료내역" ok-only>
                                <div class="row">
                                    <div class="col-md-12 mt-4 mb-1">
                                        <h6>1. 사이즈 (mm)</h6>
                                        <div class="custom-control-inline ml-3 d-flex justify-content-start">
                                            <input type="number" class="form-control w-25 text-center" placeholder="가로(mm)" v-model="item.sizeInfo.width"> x
                                            <input type="number" class="form-control w-25 text-center" placeholder="세로(mm)" v-model="item.sizeInfo.length"> x
                                            <input type="number" class="form-control w-25 text-center" placeholder="높이(mm)" v-model="item.sizeInfo.height">
                                        </div>
                                        <h6>2. 무게 (g)</h6>
                                        <div class="custom-control-inline ml-3">
                                            <input type="number" class="form-control w-50 text-center" placeholder="무게(g)" v-model="item.sizeInfo.weight">
                                        </div>
                                        <h6>3. 타겟키워드</h6>
                                        <div class="custom-control-inline ml-3">
                                            <input type="text" class="form-control w-100 text-center" placeholder="타겟키워드" v-model="item.타겟키워드">
                                        </div>
                                        <div class="ml-3 mt-3">
                                            <button type="button" class="btn btn-success mr-2 mb-4" @click="getCommission(item)">수수료 가져오기</button>
                                        </div>
                                    </div>
                                    <div class="col-md-12 mt-4 mb-1"  v-if="item.commision !== undefined">
                                        <h6>1. 풀필먼트수수료(배송비)</h6>
                                        <div class="custom-control-inline ml-3">
                                            {{item.fullfillmentFee}}
                                        </div>
                                        <h6>2. 입출고수수료</h6>
                                        <div class="custom-control-inline ml-3">
                                            {{item.warehouseFee}}
                                        </div>
                                        <h6>3. 판매수수료(판매수수료율)</h6>
                                        <div class="custom-control-inline ml-3">
                                            {{parseInt(item.commision)}}({{item.commisionRate}}%)
                                        </div>
                                    </div>
                                </div>
                            </b-modal>
                        </template>
                        <template v-slot:월판매량="{item}">
                            <td style="overflow:hidden;text-overflow: ellipsis;">{{item.월판매량Adjust ? item.월판매량Adjust : item.월판매량}}
                                <div v-if="item.type == 'trendFollower'">
                                    <b-badge variant="white"><b-icon icon="bar-chart" scale="2" :variant="chartClass(item)" v-b-modal="'reviewVerify' + item._id"></b-icon></b-badge>
                                </div>


                                <b-modal :id="'reviewVerify' + item._id" size="xl" title="리뷰검증" ok-only>
                                    <div class="row">
                                        <div class="col-md-12 d-flex justify-content-center" v-if="loadingtext">
                                            {{ loadingtext }}
                                        </div>
                                        <div class="col-md-12 d-flex justify-content-center" v-if="item.fakeReviewRate">
                                            <b-card>
                                                <ApexChart element="layout1-chart-5" :chartOption="item.chart" />
                                            </b-card>
                                        </div>
                                        <div class="col-md-12 d-flex justify-content-center">

                                            <h6>예상월판매량(전체)&nbsp; :&nbsp; {{ item.월판매량 }}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</h6>
                                            <h6>예상월매출(전체)&nbsp; :&nbsp; {{ item.월매출.toLocaleString() }}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</h6>
                                        </div>
                                        <div class="col-md-12 d-flex justify-content-center" v-if="item.fakeReviewRate">
                                            <svg width="50" height="50" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                                <path fill="currentColor" d="M12 21l-8-9h6V3h4v9h6z"/>
                                            </svg>
                                        </div>
                                        <div class="col-md-12 d-flex justify-content-center" v-if="item.fakeReviewRate">
                                            <h6 :class="classSales(item)">예상월판매량(변경) &nbsp; :&nbsp; {{ item.월판매량Adjust }}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</h6>
                                            <h6 :class="classSales(item)">예상월매출(변경) &nbsp; :&nbsp; {{ item.월매출Adjust ? item.월매출Adjust.toLocaleString() : '' }}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</h6>
                                        </div>
                                        <div class="col-md-12 d-flex justify-content-center">
                                            <div class="ml-3 mt-3">
                                                <button ref="reviewVerifyButton" type="button" class="btn btn-success mr-2 mb-4" @click="reviewVerify(item)">리뷰검증</button>
                                            </div>
                                        </div>
                                    </div>
                                </b-modal>
                            </td>
                        </template>
                        <template v-slot:월매출="{item}">
                            {{item.월매출Adjust ? item.월매출Adjust : item.월매출.toLocaleString()}}
                        </template>
                        <template v-slot:메모="{item}">
                            <input type="text" class="form-control w-100 text-center" placeholder="" v-model="item.메모">
                        </template>
                    </CDataTable>
				</div>
				<div class="d-flex justify-content-start">
					<button type="button" class="btn btn-primary mr-2 mb-4" @click="saveitem">저장</button>
					<button type="button" class="btn btn-secondary mr-2 mb-4" @click="deleteitem">삭제</button>
					<button type="button" class="btn btn-success mr-2 mb-4" @click="downloadsales">다운로드</button>
				</div>


			</div>
		</div>
		<!-- Page end  -->
	</div>
</template>
<script>
import axios from 'axios';
import { core } from '../../../config/pluginInit';
import ApexChart from '../../../components/charts/ApexChart';
// import { splice } from 'core-js/internals/enum-bug-keys';
import * as XLSX from 'xlsx';
import CDataTable from "@/components/common/CDataTable";
// import { json } from 'body-parser';
export default {
	name:'salesstatus',
	components:{
        ApexChart,
        CDataTable
    },
	data(){
		return{
            headers: [
                {text: `소싱방법\nBadge`, value: '로켓뱃지', align: 'center', width: 140, isSlot: true},
                {text: '타겟키워드', value: '타겟키워드', align: 'center', width: 120, isSlot: false},
                {text: '상품정보', value: '판매상품명', align: 'center', width: 400, isSlot: true},
                {text: '구매매칭', value: '구매가', align: 'center', width: 250, isSlot: true},
                {text: '매입가(원)', value: '매입가', align: 'center', width: 100, isSlot: false},
                {text: '예상월수익(원)', value: '월수익', align: 'center', width: 140, isSlot: true},
                {text: '예상마진(원) 마진율(%) ROI(%)', value: '마진', align: 'center', width: 100, isSlot: true},
                {text: '판매가(원)', value: '판매가', align: 'center', width: 150, isSlot: true},
                {text: '수수료(원)', value: '수수료', align: 'center', width: 100, isSlot: true},
                {text: '예상월판매량(개)', value: '월판매량', align: 'center', width: 140, isSlot: true},
                {text: '예상월매출(원)', value: '월매출', align: 'center', width: 140, isSlot: true},
                {text: '리뷰수', value: '리뷰수', align: 'center', width: 100, isSlot: false},
                {text: '리뷰평점', value: '리뷰평점', align: 'center', width: 100, isSlot: false},
                {text: '연관키워드', value: '연관키워드', align: 'center', width: 120, isSlot: false},
                {text: '메모', value: '메모', align: 'center', width: 120, isSlot: true},
            ],
            tableSelectedItem: [],
			purchaseRatio:300,
			charge: 35,

			myData : [1000,500,250,125,62,31,15,7,3,1,1,1,1,1,0],
			salesarr:[],
			importarr:[],
			stockarr:[],
			data:[],
			checkalllists:false,

			salescnt:0,
			importcnt:0,
			salesamount:0,
			supplyamount:0,
			noreview:0,
			productqty:0,
			nostock:0,
			// increview:0,
			// decreview:0,

			loadingtext:'',


			title:"장바구니",
			desc2:"상품등록 및 구매진행이 진행되지 않는 경우 14일 후에 자동으로 삭제됩니다.",
			desc1:"장바구니의 상품은 기본적으로 다른 사용자에게 노출되지 않으며",
			desc3:"장바구니 상품은 100개의 한도가 정해져 있습니다.",
			link:{ name: 'people.addusers'},
			linktext:"판매현황 수집",

			SKUID:'',
			showspinner: false,
			startdate: this.getYesterday(),
			enddate: this.getTodaywith(),
			saleslists: [],
			saleslistsinit: [],
			showlist: false,

			maxrows : 200,
			minuslimit : -2,
			applyminuslimit : false,

			UserListS:[
				{
					name:'Cliff Hanger',
					email:'cliff@gmail.com',
					company:'Product Manager',
					group:'Cliff',
					status:'Active',
					store:'1'
				},
				{
					name:'Terry Aki',
					email:'terry@gmail.com',
					company:'Stock CEO',
					group:'Terry',
					status:'Active',
					store:'2'
				}
			],
			chart4:{
				series: [
					{
						name: '일자별 리뷰수',
						data: [86, 80, 84, 95, 83, 75, 88, 76, 86, 93, 85, 65]
					},
					{
						name: '가구매의심 리뷰수',
						data: [76, 72, 76, 85, 74, 69, 80, 68, 78, 85, 77, 55]
					}
				],
				chart: {
					type: 'bar',
					stacked: true,
					height: 300,
					width: 800
				},
				colors: ['#32BDEA','#FF7E41'],
					plotOptions: {
						bar: {
								horizontal: false,
								columnWidth: '30%',
								endingShape: 'rounded'
						},
					},
					dataLabels: {
						enabled: false
					},
					stroke: {
						show: true,
						width: 3,
						colors: ['transparent']
					},
					xaxis: {
						categories: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
						labels: {
							minWidth: 0,
							maxWidth: 0
						}
					},
					yaxis: {
						show: true,
						labels: {
						minWidth: 20,
						maxWidth: 20
						}
					},
					fill: {
						opacity: 1
					},
					tooltip: {
					y: {
						formatter: function (val) {
						return val + " 개"
						}
					}
				}
			},
		}
	},
    beforeDestroy() {
		window.removeEventListener('message', this.messageEventHandler);
	},	
    mounted() {
		this.messageEventHandler = async(event) => {
			if(event.data.res && event.data.res === 'tracking'){
                if(!event.data.msg.includes('수집완료')){
					this.loadingtext = '데이터 불러오는 중 ...';
					alert(event.data.msg)
					this.showspinner = false;
					this.showlist = true;
				} else {
					this.search()
				}
            }
            if(event.data.res && event.data.res === 'trackingratio'){
                this.loadingtext = '데이터 불러오는 중 ... ' + parseInt(event.data.ratio*100) + '%';
            }
			if(event.data.res && event.data.msg === 'getCommission'){
				this.saleslists[this.currentindex];
				this.saleslists[this.currentindex].fullfillmentFee = parseInt(event.data.commission.배송비);
				this.saleslists[this.currentindex].warehouseFee = parseInt(event.data.commission.입출고비);
				this.saleslists[this.currentindex].commisionRate = event.data.commission.판매수수료;
				this.saleslists[this.currentindex].commision = parseInt(this.saleslists[this.currentindex].판매가*event.data.commission.판매수수료*0.01);
				console.log(this.saleslists[this.currentindex].fullfillmentFee)
				console.log(this.saleslists[this.currentindex].warehouseFee)
				console.log(this.saleslists[this.currentindex].commisionRate)
				console.log(this.saleslists[this.currentindex].commision)
				this.saleslists[this.currentindex].수수료 = parseInt((this.saleslists[this.currentindex].fullfillmentFee + this.saleslists[this.currentindex].warehouseFee + this.saleslists[this.currentindex].commision)*1.1);
				this.changeCost(this.saleslists[this.currentindex]);
			}
			if(event.data.res === 'reviewVerifyProgress'){
				// console.log(event.data)
				this.loadingtext = event.data.text + parseInt(event.data.rate*100) + '%';
			}
			if(event.data.res && event.data.msg === 'reviewVerify'){
				this.loadingtext = '';
				this.drawReviewData(event.data.reviewData)
			}
		}
		window.addEventListener('message', this.messageEventHandler);
        this.$nextTick(function () {
            this.search()
        })
    },
	methods: {
		chartClass(saleslist){
			if(saleslist.fakeReviewRate){
				return 'success'
			} else {
				return 'secondary'
			}
		},
		classSales(saleslist){
			if(saleslist.월판매량 !== saleslist.월판매량Adjust){
				return 'text-secondary'
			} else {
				return ''
			}
		},
		drawReviewData(r){
			var s = this.saleslists[this.currentindex];
			var productName = s.판매상품명.split(',')[0];

			let productMap = {}; // 상품명과 해당 리뷰어들을 저장

			// 1단계: reviewerHistory를 순회하면서 맵 구성
			r.reviewerHistory.forEach(review => {
				if (!review.상품명.includes(productName)) {
					// productMap 업데이트
					if (!productMap[review.상품명]) {
						productMap[review.상품명] = new Set();
					}
					productMap[review.상품명].add(review.리뷰어번호);
				}
			});

			// 2단계: 한 개 이상의 리뷰어가 구매한 상품 식별
			let duplicatePurchases = {};
			for (let product in productMap) {
				if (productMap[product].size > 1) {
					duplicatePurchases[product] = Array.from(productMap[product]);
				}
			}

			console.log(duplicatePurchases)
			// 3단계: 동일한 상품 세트를 주문한 리뷰어 식별
			let reviewerGroups = {};
			for (let product in duplicatePurchases) {
				let reviewers = duplicatePurchases[product].sort().join(',');
				if (!reviewerGroups[reviewers]) {
					reviewerGroups[reviewers] = [];
				}
				reviewerGroups[reviewers].push(product);
			}

			// 2개 이상의 상품을 주문한 리뷰어 그룹 필터링
			let result = [];
			for (let reviewers in reviewerGroups) {
				if (reviewerGroups[reviewers].length > 1) {
					result.push(reviewers.split(','));
				}
			}

			console.log(result)
			var fakeReviewer = [];
			result.flat(Infinity).forEach(e => {
				if(!fakeReviewer.includes(e)){
					fakeReviewer.push(e)
				}
			})

			console.log(fakeReviewer)

			var dates = this.getLast30DaysFromYesterdayAscending();

			var totalQty = [];
			var fakeQty = [];
			var realQty = [];
			var reviewContentQty = [];
			for(var i=0;i<30;i++){
				totalQty.push(0)
				fakeQty.push(0)
				realQty.push(0)
				reviewContentQty.push(0)
			}

			for(i=0;i<dates.length;i++){
				var e = dates[i];
				for(var f of r.reviewArr){
					if(f.작성일.replaceAll('.','-') == e){
						totalQty[i]++
						if(fakeReviewer.includes(f.리뷰어번호) || f.체험단 === true ){
							fakeQty[i]++
						} else {
							realQty[i]++
						}
						if(f.리뷰내용){
							reviewContentQty[i]++
						}
					}
				}
			}

			//해당날짜 리뷰의 절반이상에 리뷰내용이 있는 경우 이 리뷰들은 작업으로 표기
			for(i=0;i<totalQty.length;i++){
				if(totalQty[i]/2 <= reviewContentQty[i] && totalQty[i] >= 2){
					if(fakeQty[i] < reviewContentQty[i]){
						fakeQty[i] = reviewContentQty[i];
						realQty[i] = totalQty[i] - fakeQty[i]
					}
				}
			}

			console.log(totalQty)
			console.log(fakeQty)
			console.log(realQty)
			console.log(reviewContentQty)


			var realCnt = realQty.reduce((pv,cv) => {return pv+cv},0)
			var totalCnt = totalQty.reduce((pv,cv) => {return pv+cv},0)
			var fakeReviewRate = realCnt/totalCnt;
			console.log(fakeReviewRate);
			this.saleslists[this.currentindex].fakeReviewRate = '';
			this.saleslists[this.currentindex].월판매량Adjust = parseInt(this.saleslists[this.currentindex].월판매량*fakeReviewRate)
			this.saleslists[this.currentindex].월매출Adjust = parseInt(this.saleslists[this.currentindex].월매출*fakeReviewRate)
			this.saleslists[this.currentindex].chart = {
				series: [
					{
						name: '일자별 리뷰수',
						data: realQty
					},
					{
						name: '가구매의심 리뷰수',
						data: fakeQty
					}
				],
				chart: {
					type: 'bar',
					stacked: true,
					height: 300,
					width: 800
				},
				colors: ['#32BDEA','#FF7E41'],
					plotOptions: {
						bar: {
								horizontal: false,
								columnWidth: '30%',
								endingShape: 'rounded'
						},
					},
					dataLabels: {
						enabled: false
					},
					stroke: {
						show: true,
						width: 3,
						colors: ['transparent']
					},
					xaxis: {
						categories: dates,
						labels: {
							minWidth: 0,
							maxWidth: 0
						}
					},
					yaxis: {
						show: true,
						labels: {
						minWidth: 20,
						maxWidth: 20
						}
					},
					fill: {
						opacity: 1
					},
					tooltip: {
					y: {
						formatter: function (val) {
							return val + " 개"
						}
					}
				}
			}
			this.saleslists[this.currentindex].fakeReviewRate = fakeReviewRate;
			// this.$refs.reviewVerifyButton.disabled = false;

		},
		getLast30DaysFromYesterdayAscending() {
			let dates = [];
			let today = new Date();
			let yesterday = new Date(today);
			yesterday.setDate(yesterday.getDate() - 1);

			for (let i = 29; i >= 0; i--) {
				let date = new Date(yesterday);
				date.setDate(yesterday.getDate() - i);
				let formattedDate = date.toISOString().split('T')[0]; // 날짜를 "YYYY-MM-DD" 형식으로 변환
				dates.push(formattedDate);
			}

			return dates;
		},
		reviewVerify(saleslist){
			// this.$refs.reviewVerifyButton.disabled = true;
			this.currentindex = this.saleslists.findIndex(i => i === saleslist);
			saleslist.fakeReviewRate = '';
			var link = saleslist.판매링크;
			window.postMessage({ greeting: "reviewVerify", companyid: this.$store.state.user.companyid, suppliercode: this.$store.state.user.suppliercode, link:link}, "*",)
		},
		getCommission(saleslist){
			this.currentindex = this.saleslists.findIndex(i => i === saleslist);
			var obj = {
				x : saleslist.sizeInfo.width,
				y : saleslist.sizeInfo.length,
				z : saleslist.sizeInfo.height,
				w : saleslist.sizeInfo.weight,
				categoryId : '',
				price : saleslist.판매가,
				type : saleslist.type,
				keyword : saleslist.타겟키워드,
				link : saleslist.판매링크
			}
			window.postMessage({ greeting: "getCommission", companyid: this.$store.state.user.companyid, suppliercode: this.$store.state.user.suppliercode, info:obj}, "*",)
		},
		selectline(){
			var pageInfo = core.checkpage();
			for(var i=0;i<this.saleslists.length;i++){
				if(i >= pageInfo.start && i < pageInfo.end){
					this.saleslists[i].checked = true
				}
			}
		},
		showts(){
            this.showlist=false;
            setTimeout(() => {
                this.saleslists = this.saleslistsinit.filter(e => e.type == 'trendSetter');
                this.showlist = true;
            }, 1);
        },
		showtf(){
            this.showlist=false;
            setTimeout(() => {
                this.saleslists = this.saleslistsinit.filter(e => e.type == 'trendFollower');
                this.showlist = true;
            }, 1);
        },
		previmg(saleslist,bool){
			if(bool){
				this.showlist = false;
			}

			if(saleslist.currentindex == 0){
				saleslist.currentindex = saleslist.상품매칭.length - 1;
				return
			}
			saleslist.currentindex--

			if(bool){
				this.applyCurrentObj(saleslist);
				setTimeout(()=>{
					this.showlist = true;
				},1)
			}
		},
		nextimg(saleslist,bool){
			if(bool){
				this.showlist = false;
			}
			if(saleslist.currentindex == saleslist.상품매칭.length - 1){
				saleslist.currentindex = 0;
				return
			}
			saleslist.currentindex++
			if(bool){
				this.applyCurrentObj(saleslist);
				setTimeout(()=>{
					this.showlist = true;
				},1)
			}
		},
		applyCurrentObj(saleslist){
			var e = saleslist;
			saleslist.월수익 = (e.판매가*0.9 - parseInt(e.상품매칭[e.currentindex].price*this.purchaseRatio))*parseInt(e.월매출/e.판매가)
			saleslist.마진 = e.판매가*0.9 - parseInt(e.상품매칭[e.currentindex].price*this.purchaseRatio)
			saleslist.마진율 = parseInt((e.판매가*0.9 - parseInt(e.상품매칭[e.currentindex].price*this.purchaseRatio))/e.판매가*100)
			saleslist.매입가 = parseInt(e.상품매칭[e.currentindex].price*this.purchaseRatio)
		},
		changeCostEnter(event,saleslist){
			if(event.keyCode === 13){
				this.changeCost(saleslist)
			}
		},
		changeCost(saleslist){
			saleslist.매입가 = parseInt(saleslist.구매가*saleslist.매입가율);
			saleslist.부가세 = saleslist.판매가/11 - saleslist.구매가*saleslist.매입가율/11 - saleslist.수수료/11
			saleslist.마진 = parseInt(saleslist.판매가 - saleslist.매입가 - saleslist.수수료 - saleslist.부가세);
			saleslist.ROI = (saleslist.마진/saleslist.매입가)*100
			saleslist.마진율 = (saleslist.마진/saleslist.판매가)*100;
			saleslist.월수익 = (saleslist.마진*parseInt(saleslist.월매출/saleslist.판매가));
		},
		openWebsite(url) {
			window.open(url, "_blank");
		},
		linktopurchase(purchase){
			window.open(purchase.detail_url,"_blank");
		},
		applycorrect(){
			if(this.data.length > 0){
				this.showspinner = true;
				this.showlist = false;
				this.searchresult()
			} else {
				alert('장바구니에 찜한 상품이 없습니다.')
				return
			}
		},
		checkalllist(){
			if(this.checkalllists){
				this.saleslists.forEach(e => {
					e.checked = true
				})
			} else {
				this.saleslists.forEach(e => {
					e.checked = false
				})
			}
		},
		saveitem(){
			if(confirm('현재 상태를 저장하시겠습니까?')){
				axios.post('/api/nomad/updatesaveitemM',this.tableSelectedItem)
				.then(result => {
					if(result.data.res == 'OK'){
						alert('저장완료')
						console.log(result)
					} else {
						alert(result.data.alert)
						console.log(result)
					}
				})
			}
		},
		deleteitem(){
			if(this.tableSelectedItem.length === 0){
				alert('삭제할 상품을 선택해 주세요')
			} else {
				if(this.tableSelectedItem === this.saleslists.length){
					if(!confirm('전체 내역을 삭제하시겠습니까?')){
						return
					}
				} else {
					if(!confirm(this.tableSelectedItem.length + '개의 상품을 삭제하시겠습니까?')){
						return
					}
				}
				axios.post('/api/nomad/deletesaveitemM',this.tableSelectedItem)
				.then(result => {
					if(result.data.res == 'OK'){
						this.search()
					} else {
						alert('삭제실패')
						console.log(result)
					}
				})
			}
		},
		collecttr(){
			window.postMessage({greeting: "collecttracking", companyid:this.$store.state.user.companyid},"*",)
			this.showspinner = true;
			this.showlist = false;
		},
		modalID(index) {
            // console.log(index);
            return 'modal' + index
        },
		modalIDca(index) {
            // console.log(index);
            return 'modalca' + index
        },
		salesdownload(){
			if(this.saleslists.length == 0){
				alert('다운로드할 내역이 없습니다.')
				return
			}
			const workBook = XLSX.utils.book_new()
			var obj = {};
			var arr = [];
			this.saleslists.forEach(e => {
				arr = [];
				e.판매세부내역.forEach(ele => {
					obj = {
						상품명 : e.판매상품명,
						Date : ele.date,
						재고 : ele.재고,
						판매 : ele.판매,
						판매가 : ele.판매가,
						판매매출 : ele.판매매출,
						리뷰수 : ele.리뷰수,
						리뷰평점 : ele.리뷰평점
					}
					arr.push(obj)
				})
				var workSheet = XLSX.utils.json_to_sheet(arr)
				XLSX.utils.book_append_sheet(workBook, workSheet, e.vendorItemId.toString())
			})


			XLSX.writeFile(workBook, '일자별판매현황.xlsx')
		},
		search(){
			this.showspinner = true;
			this.showlist = false;
			axios.post('/api/nomad/getsaveitemM',{companyid:this.$store.state.user.companyid})
			.then(result => {
                if(result.data.length > 0){
					console.log(result.data)
					this.searchresult(result.data)
                } else {
                    alert('추가된 모니터링 상품이 없습니다.')
                    return
                }
			})
		},
		datetotimestamp(a){
			var date = new Date(a);
			return date.getTime();
		},
        searchresult(data){
            this.saleslists = [];
            this.saleslistsinit = [];
            var obj = {}
            var arr = [];
            data.forEach(e => {
				if(!e.sizeInfo){
					e.sizeInfo = {
						width:100,
						length:100,
						height:100,
						weight:100
					}
				}
				if(!e.ROI && e.매입가 && e.마진){
					e.ROI = (e.마진/e.매입가)*100
				}
				obj = {
					_id:e._id,
					checked : false,
					isLoading : false,
					썸네일 : e.썸네일,
					판매상품명 : e.판매상품명,
					productId : e.productId,
					판매가 : e.판매가,
					타겟키워드 : e.타겟키워드,
					연관키워드 : e.연관키워드,
					리뷰수 : e.리뷰수,
					리뷰평점 : e.리뷰평점,
					type:e.type,
					월수익 : e.월수익,
					마진 : e.마진,
					ROI : e.ROI,
					구매가 : e.구매가,
					마진율 : e.마진율,
					매입가 : e.매입가,
					매입가율 : e.매입가율,
					수수료 : e.수수료,
					월판매량 : e.월판매량,
					월매출 : e.월매출,
					상품매칭 : e.상품매칭,
					판매링크 : e.판매링크,
					currentindex : e.currentindex,
					로켓뱃지 : e.로켓뱃지,
					sizeInfo : e.sizeInfo,
					commision : e.commision,
					commisionRate : e.commisionRate,
					fullfillmentFee : e.fullfillmentFee,
					warehouseFee : e.warehouseFee,
					메모 : e.메모,
					chart : e.chart,
					fakeReviewRate : e.fakeReviewRate,
					월매출Adjust : e.월매출Adjust,
					월판매량Adjust : e.월판매량Adjust
				}
				arr.push(obj)
            })
            arr.forEach(e => {
                this.saleslists.push(this.deepClone(e))
                this.saleslistsinit.push(this.deepClone(e))
            })

            if(this.saleslists.length == data.length){
				setTimeout(() => {
					this.showlist = true;
					this.showspinner = false;
				}, 10);
            }
			console.log(this.saleslists)

        },
		timestamptodate(timestamp){
			var d = new Date(timestamp + (1000*60*60*9));
			return d.toISOString().replace('T',' ').slice(0,19)
		},
		searchresult2(){
			this.saleslists = [];
			this.salesarr = [];
			this.saleslistsinit = [];
			var obj = {};
			var d = {};
			// var 이전재고 = '';
			// var t = {};
			// var su = {};
			// var 입고수량 = 0;
			var sales = [];
			this.$store.state.sales.forEach(e => {
				sales.push(this.deepClone(e))
			})
			var supply = [];
			this.$store.state.supply.forEach(e => {
				supply.push(this.deepClone(e))
			})
			var productdb = this.$store.state.productdb.filter(e => !e.등록이미지?.includes('vendor_inventory'));
			if(this.$store.state.sales.length > 0){
				console.log(this.$store.state.sales)
				console.log(this.$store.state.supply)
				var dates = [];
				sales.forEach(e => {
					if(!dates.find(ele => ele.date == e.date)){
						dates.push({date : e.date})
					}
				})
				supply.forEach(e => {
					if(!dates.find(ele => ele.date == e.importdate) && dates[0].date < e.importdate){
						dates.push({date : e.importdate})
					}
				})
				// console.log(dates)
				dates.sort(function(a,b){
					if (a.date > b.date){
						return 1;
					}
					if (a.date < b.date){
						return -1;
					}
					return 0;
				})
				productdb.sort(function(a,b){
					if (a["SKU ID"] > b["SKU ID"]){
						return 1;
					}
					if (a["SKU ID"] < b["SKU ID"]){
						return -1;
					}
					return 0;
				})
				sales.forEach(e => {
					e.판매세부내역.sort(function(a,b){
						if (a.sk > b.sk){
							return 1;
						}
						if (a.sk < b.sk){
							return -1;
						}
						return 0;
					})
				})
				supply.forEach(e => {
					e.importlist.sort(function(a,b){
						if (a.sku번호 > b.sku번호){
							return 1;
						}
						if (a.sku번호 < b.sku번호){
							return -1;
						}
						return 0;
					})
				})



				for(var i=0;i<productdb.length;i++){
					var pred = {
						재고 : 0,
						입고 : 0,
						리뷰수 : 0,
						리뷰평점 : 0,
						판매가 : 0,
					}
					obj = {
						썸네일 : productdb[i].썸네일,
						판매상품명 : productdb[i].판매상품명,
						SKUID : productdb[i]["SKU ID"],
						바코드 : productdb[i].바코드,
						발주가능상태 : productdb[i].발주가능상태,
						재고 : 0,
						판매 : 0,
						입고 : 0,
						판매가 : 0,
						판매매출 : 0,
						공급매출 : 0,
						리뷰수 : 0,
						리뷰평점 : 0,
						판매링크 : productdb[i].판매링크,
						판매세부내역 : []
					}
					var obsales = {
						판매상품명 : productdb[i].판매상품명,
						SKUID : productdb[i]["SKU ID"],
						바코드 : productdb[i].바코드
					}
					var obstock = {
						판매상품명 : productdb[i].판매상품명,
						SKUID : productdb[i]["SKU ID"],
						바코드 : productdb[i].바코드
					}
					var obimport = {
						판매상품명 : productdb[i].판매상품명,
						SKUID : productdb[i]["SKU ID"],
						바코드 : productdb[i].바코드
					}
					dates.forEach((e,k) => {

						d = {
							date : e.date,
							발주가능상태 : productdb[i].발주가능상태,
							재고 : 0,
							판매 : 0,
							입고 : 0,
							판매가 : 0,
							판매매출 : 0,
							공급매출 : 0,
							리뷰수 : 0,
							리뷰평점 : 0,
							판매링크 : productdb[i].판매링크,
							재고확인시간 : 0,
						}
						// console.log(e)
						// console.log(sales.findIndex(ele => ele.date == e.date))
						if(sales.findIndex(ele => ele.date == e.date) >= 0){
							var j = sales.findIndex(ele => ele.date == e.date)
							// console.log(j)
							for(var n=0;n<sales[j].판매세부내역.length;n++){

								if(parseInt(sales[j].판매세부내역[n].sk) == parseInt(productdb[i]["SKU ID"])){
									if(productdb[i]["SKU ID"] === 17064982 || productdb[i]["SKU ID"] ===  27573440){
										console.log(e)
										console.log(sales[j].판매세부내역[n])
									}
									d.재고 = sales[j].판매세부내역[n].s === 0 || sales[j].판매세부내역[n].s > 0 ? parseInt(sales[j].판매세부내역[n].s) : sales[j].판매세부내역[n].s < 0 ? 0 : pred.재고;
									d.리뷰수 = sales[j].판매세부내역[n].rq === 0 || sales[j].판매세부내역[n].rq ? parseInt(sales[j].판매세부내역[n].rq) : pred.리뷰수;
									d.리뷰평점 = sales[j].판매세부내역[n].rp === 0 || sales[j].판매세부내역[n].rp ? parseFloat(sales[j].판매세부내역[n].rp) : pred.리뷰평점;
									d.판매가 = sales[j].판매세부내역[n].p === 0 || sales[j].판매세부내역[n].p ? parseFloat(sales[j].판매세부내역[n].p) : pred.판매가;
									d.재고확인시간 = sales[j].time ? sales[j].time : 0;
									sales[j].판매세부내역.splice(n,1)
									// console.log(sales[j].판매세부내역.length)
									break;
								}
								if(parseInt(sales[j].판매세부내역[n].sk) > parseInt(productdb[i]["SKU ID"])){
									d.재고 = pred.재고;
									d.리뷰수 = pred.리뷰수;
									d.리뷰평점 = pred.리뷰평점;
									d.판매가 = pred.판매가;
									break;
								}
							}
						} else {
							d.재고 = pred.재고;
							d.리뷰수 = pred.리뷰수;
							d.리뷰평점 = pred.리뷰평점;
							d.판매가 = pred.판매가;
						}
						// if(productdb[i]["SKU ID"] === 18462723){
						// 	console.log(d.재고)
						// }
						if(supply.findIndex(ele => ele.importdate == e.date) >= 0){
							j = supply.findIndex(ele => ele.importdate == e.date)
							for(n=0;n<supply[j].importlist.length;n++){
								// console.log(supply[j].importlist[n])
								// console.log(supply[j].importlist[n].sku번호)
								if(parseInt(supply[j].importlist[n].sku번호) == parseInt(productdb[i]["SKU ID"])){
									// if(productdb[i]["SKU ID"] === 17064982 || productdb[i]["SKU ID"] ===  27573440){
									// 	console.log(e)
									// 	console.log(supply[j].importlist[n])
									// 	console.log(d.재고)
									// 	console.log(d.재고확인시간)
									// 	console.log(this.datetotimestamp(supply[j].importlist[n]['입고/반출일자']))
									// 	console.log(d.재고확인시간 < this.datetotimestamp(supply[j].importlist[n]['입고/반출일자']))
									// }
									d.입고 += parseInt(supply[j].importlist[n].수량);
									if(d.재고확인시간 <= this.datetotimestamp(supply[j].importlist[n]['입고/반출일자'])){
										d.재고 += parseInt(supply[j].importlist[n].수량);
									}
									// if(productdb[i]["SKU ID"] === 18462723){
									// 	console.log(supply[j].importlist[n])
									// 	console.log(d.재고)
									// }
								}
								if(parseInt(supply[j].importlist[n].sku번호) > parseInt(productdb[i]["SKU ID"])){
									// d.입고 = pred.입고;
									// d.재고 = pred.재고;
									break;
								}
							}
						}
						if(k == 0){
							d.판매 = 0;
						} else {
							d.판매 = pred.재고 - d.재고 + d.입고;
						}
						d.판매매출 = d.판매가 * d.판매;
						d.공급매출 = productdb[i].매입가 ? productdb[i].매입가 * d.판매 : productdb[i].현재매입가 ? productdb[i].현재매입가 * d.판매 : 0;
						pred = d;
						obj.판매세부내역.push(d)

						obj.재고 = d.재고;
						obj.판매 += d.판매;
						obj.입고 += d.입고;
						obj.판매매출 += d.판매매출;
						obj.공급매출 += d.공급매출;
						obj.판매가 = d.판매가;
						obj.리뷰수 = d.리뷰수;
						obj.리뷰평점 = d.리뷰평점;

						obsales[e.date] = d.판매;
						obimport[e.date] = d.입고;
						obstock[e.date] = d.재고;
					})
					this.salesarr.push(obsales);
					this.stockarr.push(obstock);
					this.importarr.push(obimport);

					this.saleslists.push(this.deepClone(obj))
					this.saleslistsinit.push(this.deepClone(obj))
				}


				this.salescnt = this.saleslists.reduce((pv,rv) => { return pv + rv.판매},0)
				this.importcnt = this.saleslists.reduce((pv,rv) => { return pv + rv.입고},0)
				this.salesamount = this.saleslists.reduce((pv,rv) => { return pv + rv.판매매출},0)
				this.supplyamount = this.saleslists.reduce((pv,rv) => { return pv + rv.공급매출},0)
				this.noreview = this.saleslists.filter(e => e.리뷰수 == 0).length;
				this.productqty = this.saleslists.length;
				this.nostock = this.saleslists.filter(e => e.재고 == 0).length;

				if(this.saleslists.length == 0){
					alert("판매현황이 없습니다.")
					this.showspinner = false;
					return
				}
				setTimeout(() => {
					this.showlist = true;
					this.showspinner = false;
				}, 1);
			} else {
				alert("판매현황이 없습니다.")
				this.showspinner = false;
				return
			}
			console.log(this.salesarr)
		},

		deepClone(obj) {
			if (obj === null || typeof obj !== "object") {
				return obj
			}

			const result = Array.isArray(obj) ? [] : {}

			for (let key of Object.keys(obj)) {
				result[key] = this.deepClone(obj[key])
			}

			return result
		},
		todate(date){
			var today = new Date(date);
			today.setHours(today.getHours() + 9);
			return today.toISOString().replace('T', ' ').substring(0, 19);
		},
        timestamp(date){
            //inputdate = "yyyy-mm-dd"
            return new Date(date).getTime()
        },
		getTodaywith(){
			var date = new Date();
			var year = date.getFullYear();
			var month = ("0" + (1 + date.getMonth())).slice(-2);
			var day = ("0" + date.getDate()).slice(-2);

			return year + "-" + month + "-" + day;
		},
		getDatewith(d){
			var date = new Date(d);
			var year = date.getFullYear();
			var month = ("0" + (1 + date.getMonth())).slice(-2);
			var day = ("0" + date.getDate()).slice(-2);

			return year + "-" + month + "-" + day;
		},
		getYesterday(){
			return this.getDatewith(this.datetotimestamp(this.getTodaywith()) - 1000*60*60*24)
		},
		downloadsales() {
			if(this.tableSelectedItem.length === 0){
				alert('다운로드할 내역이 없습니다.')
				return
			}
			function getToday(){
				var date = new Date();
				var year = date.getFullYear();
				var month = ("0" + (1 + date.getMonth())).slice(-2);
				var day = ("0" + date.getDate()).slice(-2);

				return year + month + day;
			}
			if(this.tableSelectedItem.length === 0){
                this.tableSelectedItem = this.saleslists
			}
			var arr = [];
			var obj = {};
            this.tableSelectedItem.forEach(e => {
				if(e.로켓뱃지 == 'https://image7.coupangcdn.com/image/coupang/rds/logo/iphone_2x/logoRocketMerchantLargeV3R3@2x.png'){
					var 로켓뱃지 = '판매자로켓'
				}
				if(e.로켓뱃지 == 'https://image6.coupangcdn.com/image/cmg/icon/ios/logo_rocket_large@3x.png'){
					로켓뱃지 = '로켓배송'
				}
				if(e.로켓뱃지 == ''){
					로켓뱃지 = '마켓플레이스'
				}
				obj = {
					썸네일 : e.썸네일,
					판매상품명 : e.판매상품명,
					상품번호 : e.productId,
					판매가 : e.판매가,
					타겟키워드 : e.타겟키워드,
					리뷰수 : e.리뷰수,
					리뷰평점 : e.리뷰평점,
					소싱타입 : e.type,
					월수익 : e.월수익,
					마진 : e.마진,
					마진율 : e.마진율,
					ROI : e.마진/e.매입가,
					매입가 : e.매입가,
					수수료 : e.수수료,
					월판매량 : e.월판매량,
					월매출 : e.월매출,
					로켓뱃지 : 로켓뱃지,
					판매상품링크 : e.판매링크,
					구매링크:'',
					'1.타겟키워드/상품간 매칭성(TS)':'',
					'2.지재권 문제':'',
					'3.마진율/ROI 만족여부':'',
					'4.타겟키워드 상품대비 차별성':'',
					'5.쿠팡 연관검색어에 동일상품 여부':'',
					'6.KC인증/전파인증/식검':'',
					'7.최근 1개월 가리뷰(TF)':'',
					'8.리뷰상 상품 문제점':'',
					'9.타겟키워드 재설정':'',

				}
				arr.push(obj)
			})


			const workBook = XLSX.utils.book_new()
			const workSheet1 = XLSX.utils.json_to_sheet(arr)
			XLSX.utils.book_append_sheet(workBook, workSheet1, '경쟁사판매현황')
			XLSX.writeFile(workBook, '경쟁사판매현황' + '_' + getToday() + '.xlsx')
		}
	}

}
</script>

<style>
.my-class .dropdown-menu {
	max-height: 300px;
	width:800px;
	overflow-y: auto;
}

</style>
